<template>

  <v-container
    fluid
    tag="section">

<v-dialog  v-model="alert_msg"  width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Mensagem
        </v-card-title>

        <v-card-text>
            {{msg_dialog}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="ok()"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>



  <base-material-card
      icon="mdi-account-edit"
      title="Atualização de Cadastro"
      class="px-5 py-3">
<v-form ref="form">
  <v-card-text>
      <v-container class="text-center">
        <v-row>   
           <v-col cols="12" sm="6">
              <v-text-field 
              label="Celular do Cliente *"  v-mask="'(##) #####-####'"  Required :rules="[v => !!v || 'Campo Obrigatório']" style="margin-left:4px;"
              filled v-model="sms.telefone_sms"
              prepend-inner-icon="mdi-cellphone-basic"
            ></v-text-field>            
            </v-col>

          <v-col cols="12">

              <v-btn color="purple" style="font-size:16px; heigt:28px;" :loading="this.btn_load" @click="enviar()" >Enviar Sms 
                <v-icon right dark>mdi-send</v-icon>
              </v-btn>
          
              <v-btn color="success" style="font-size:16px; heigt:28px;"  @click="acessar_url()" >Iniciar 
                <v-icon right dark>mdi-account-edit</v-icon>
              </v-btn>
          </v-col> 

       

        </v-row>
      </v-container> 


 Informação: <br/>
          - INICIAR: Será redirecionado para o site do previda vinculado ao seu Token<br/>
          - ENVIAR SMS: Será enviado para o telefone do cliente um link da área do cliente vinculado ao seu Token. <br/>



    <v-spacer> </v-spacer>
    <br/>
     
  </v-card-text>  
</v-form>

    <v-data-table
            :headers="headers_sms"
            :items="lista_sms"
            :items-per-page="5"
            class="elevation-1"
          ></v-data-table>


  </base-material-card>
  </v-container>
</template>
<script>
  import Promotor from '../../services/promotor'

  export default {
    name: 'Promotor',

    mounted() {
      this.lista();
    },

    data () {
      return {
         components: {
          DashboardCoreView: () => import('../dashboard/components/core/View'),
          },
          sms:{
           telefone_sms: '',
           tokenPromotor: '',
           msg: ''

         },
        btn_load: false,
        alert_msg:false,
        msg_dialog:'',
        lista_sms:[],
         headers_sms: [
          { text: 'Data', value: 'data_insert' },
          { text: 'Telefone', value: 'telefone_sms' },

        ],
      }
    },

    methods: {
       acessar_url(){
        var key = sessionStorage.getItem('key');
        var end = "https://previdawebsac.gpdf.com.br/?token="+key+"#/";
        window.open(end, '_blank');
      },

      clear () {
          this.$refs.form.reset();
      },

      lista(){
          var token = sessionStorage.getItem('token');
         Promotor.lista_envio_sms_atualizacao(token).then(response => {
                if(response.status = 200){
                    this.lista_sms = response.data;
                }
              }).catch(e => {
              }).finally(() => {
            });
      },

      ok(){
        this.alert_msg = false;
        this.lista(); 
      },

      enviar(){
        this.btn_load = true;
        if(this.$refs.form.validate()){
        var key = sessionStorage.getItem('key');
         var token = sessionStorage.getItem('token');

          this.sms.msg = "https%3A%2F%2Fprevidawebsac.gpdf.com.br%2F?token="+key+"%23%2F";
          this.sms.tokenPromotor = token;

         // this.sms.s = token;

            Promotor.envio_sms_atualizacao(this.sms).then(response => {
              
                //var token = sessionStorage.getItem('token');
                //var end = "http://localhost:8080/?ID="+token+"#/";
              if(response.data.codigo){
                  this.alert_msg = true;
                  this.msg_dialog = response.data.mensagem;
              }

              }).catch(e => {
              if(e.status === 401){
                 this.loading = false
                   this.alert_msg = true;
                   this.btn_load = false;
                   this.msg_dialog = "Por Favor tente novamente mais tarde!";
              }
              if(e.response.status === 403){
                  alert('Usuário não tem permissão')
                }
             }).finally(() => {
                this.loading = false;
                this.btn_load = false;
                this.clear();
              })
              
        
        }else{
          this.alert_msg = true;
          this.msg_dialog = "Preencha todos os campos";
          this.btn_load = false;

        }

      },
     
     
    },
  }
</script>
